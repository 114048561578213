<template>
    <!-- Modal -->
    <div class="modal fade" id="changePublicationStatus" tabindex="-1" aria-labelledby="changePublicationStatusLabel" aria-hidden="true">
        <div class="modal-dialog">
            <form method="POST" class="" @submit.prevent="changePublicationStatus">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="changePublicationStatusLabel">Zmiana statusu wydania</h5>
                        <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <!--<ErrorResponse :message="intergrationAddError.message" v-if="intergrationAddError.isError" />-->
                        <div class="form-group mt-2">
                            <div class="form-outline">
                                <select id="formVersion" class="form-control form-control-lg custom-form-control" v-model="modelChangePublication.PublicationID">
                                    <option value="">---</option>
                                    <option v-for="(item, index) in availablePublicationStatuses" :key="index" :value="item.id">{{item.name}}</option>
                                </select>
                                <label class="form-label custom-form-label" for="formVersion">Status publikacji</label>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-mdb-dismiss="modal" id="close-changePublicationStatusLabel" @click="changeReleasePublicationClose">Zamknij</button>
                        <button type="submit" class="btn btn-primary">Zapisz</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script charset="utf-8">
    import IntegrationService from "@/services/integration/integration.service";
    export default {
        components: {
        },

        props: {
            releaseId: {
                type: Number,
                required: true,
            },

            currentPublicationId: {
                type: String,
                required: true,
            },


        },
        emits: ["current-release-item"],
        data() {
            return {
                publicPath: process.env.BASE_URL,

                availablePublicationStatuses: [],

                modelChangePublication: {
                    ReleaseID: -1,
                    PublicationID: "",
                },

                loading: {
                    fetchPublicationStatus: false,
                    saving: false,
                },
            };
        },
        watch: {
            releaseId: {
                immediate: true,
                handler(val) {
                    this.modelChangePublication.ReleaseID = val;
                    this.modelChangePublication.PublicationID = this.currentPublicationId;
                }
            },
        },
        computed: {

        },
        mounted() {
            this.fetchAvailablePublicationStatuses();
        },
        methods: {
            async fetchAvailablePublicationStatuses() {
                try {
                    this.loading.fetchPublicationStatus = true;

                    let res = await IntegrationService.GetAvailableReleasePublicationStatuses();

                    if (res.status == 200) {
                        this.availablePublicationStatuses = res.data;
                    }
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading.fetchPublicationStatus = false;
                }
            },

            async changePublicationStatus() {
                try {
                    this.loading.saving = true;

                    let res = await IntegrationService.UpdateIntegrationReleasePublication(this.modelChangePublication);

                    if (res.status == 200) {
                        this.$toast.success("Zaktualizowano status publikacji");
                        this.$emit("current-release-item", res.data);
                        document.getElementById("close-changePublicationStatusLabel").click();
                    }
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading.saving = false;
                }
            },

            changeReleasePublicationClose() {
                this.modelChangePublication.StatusID = "";
                this.modelChangePublication.PublicationID = -1;
            }
        }
    }
</script>

<style type="text/css">
</style>