import url from "@/services/common/url";
import api from "@/services/common/api";

class EventRunService {
    GetAll() {
        return api.get(url.appLogs.eventRun.getAll).then((res) => { return res; });
    }

    GetAllByToken(token) {
        return api.get(url.appLogs.eventRun.getAllByToken + "/" + token).then((res) => { return res; });
    }


    GetById(id) {
        return api.get(url.appLogs.eventRun.getById + `/${id}`)
            .then((res) => { return res });
    }

    Update(id, req) {
        return api.put(url.appLogs.eventRun.update + `/${id}`, req).then((res) => { return res; });
    }

    Delete (id) {
        return api.delete(url.appLogs.eventRun.delete + `/${id}`).then((res) => { return res; });
    }

    DeleteMany(req) {
        return api.put(url.appLogs.eventRun.deleteMany, req).then((res) => { return res; });
    }


}

export default new EventRunService();