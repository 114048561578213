import 'mdb-vue-ui-kit/css/mdb.min.css';
import Toaster from '@meforma/vue-toaster';
import { createApp } from 'vue'
import App from './App.vue'

import store from "@/store";
import router from "@/router";
import setupInterceptors from "@/services/common/setup.interceptors";
import moment from 'moment';
moment.locale('pl');
setupInterceptors(store, router);

let app = createApp(App)

app.use(router);
app.use(store)
app.use(Toaster, {
    // One of the options
    position: 'bottom-right'
})
app.mount('#app')
