<template>
    <router-view />
</template>

<script charset="utf-8">

    export default {
        components: {
        },
        data() {
            return {
                publicPath: process.env.BASE_URL
            };
        },
        computed: {

        },
        methods: {
        }
    }
</script>

<style>
    #app {
        font-family: Roboto, Helvetica, Arial, sans-serif;
    }

    .custom-form-control {
        border: 2px solid #3b71ca !important;
    }

    .form-outline .form-control.form-control-lg:focus ~ .form-label, .form-outline .form-control.form-control-lg.active ~ .form-label {
        transform: translateY(-2.25rem) translateY(0.1rem) scale(0.9);
    }

    .dropdown .dropdown-menu .item-danger {
        background: #dc4c64;
        color: #fff;
    }

    .dropdown .dropdown-menu .item-danger:hover {
        background: #dc5664;
    }
    .dropdown .dropdown-toggle::after {
        content:"";
        display:none;
    }
</style>
