import moment from 'moment';

class DateFormatter {
    Format(date) {
        console.log("data", date);
        return moment(date).format('LLL');
    }

    RelativeFormat(date) {
        return moment(date, "YYYYMMDDh:mm:ss").fromNow();
    }
}

export default new DateFormatter();