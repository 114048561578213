<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-between px-md-1">
                <div class="align-self-center">
                    <i class="fas fa-pencil-alt text-info fa-3x"></i>
                </div>
                <div class="text-end">
                    <h3>{{integrationCount}}</h3>
                    <p class="mb-0">Liczba wydań</p>
                </div>
            </div>
        </div>
        <BoxLoader v-if="loading.fetch" />
    </div>

</template>

<script charset="utf-8">
    import IntegrationService from "@/services/integration/integration.service";
    import BoxLoader from "@/components/common/BoxLoader.vue"
    export default {
        components: {
            BoxLoader
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                
                integrationCount: 0,

                loading: {
                    fetch: false
                },
            };
        },
        computed: {

        },
        mounted() {
            this.fetchIntegrationCount();
        },
        methods: {
            async fetchIntegrationCount() {
                try {
                    this.loading.fetch = true;

                    let res = await IntegrationService.GetReleasesCount();
                    if (res.status == 200) {
                        this.integrationCount = res.data;
                    }
                }
                catch (e) {
                    console.log(e)
                }
                finally {
                    this.loading.fetch = false;
                }
            }
            
        }
    }
</script>
<style type="text/css">

</style>