<template>
    <!-- Modal -->
    <div class="modal fade" id="changeReleaseStatus" tabindex="-1" aria-labelledby="changeReleaseStatusLabel" aria-hidden="true">
        <div class="modal-dialog">
            <form method="POST" class="" @submit.prevent="updateIntegrationRelease">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="changeReleaseStatusLabel">Zmiana statusu wydania</h5>
                        <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <!--<ErrorResponse :message="intergrationAddError.message" v-if="intergrationAddError.isError" />-->
                        <div class="form-group mt-2">
                            <div class="form-outline">
                                <select id="formVersion" class="form-control form-control-lg custom-form-control" v-model="modelChangeStaus.StatusID">
                                    <option value="">---</option>
                                    <option v-for="(item, index) in availableStatuses" :key="index" :value="item.id">{{item.name}}</option>
                                </select>
                                <label class="form-label custom-form-label" for="formVersion">Status aplikacji</label>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-mdb-dismiss="modal" id="close-changeReleaseStatus" @click="changeReleaseStatusClose">Zamknij</button>
                        <button type="submit" class="btn btn-primary">Zapisz</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script charset="utf-8">
    import IntegrationService from "@/services/integration/integration.service";
    export default {
        components: {
        },

        props: {
            releaseId: {
                type: Number,
                required: true,
            },

            currentStatusId: {
                type: String,
                required: true,
            },


        },
        emits: ["current-release-item"],
        data() {
            return {
                publicPath: process.env.BASE_URL,

                availableStatuses: [],

                modelChangeStaus: {
                    ReleaseID: -1,
                    StatusID: "",
                },

                loading: {
                    fetchStatus: false,
                    saving: false,
                },
            };
        },
        watch: {
            releaseId: {
                immediate: true,
                handler(val) {
                    this.modelChangeStaus.ReleaseID = val;
                    this.modelChangeStaus.StatusID = this.currentStatusId;
                }
            },
        },
        computed: {

        },
        mounted() {
            this.fetchAvailableStatuses();
        },
        methods: {
            async fetchAvailableStatuses() {
                try {
                    this.loading.fetchStatus = true;

                    let res = await IntegrationService.GetAvailableReleaseStatuses();

                    if (res.status == 200) {
                        this.availableStatuses = res.data;
                    }
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading.fetchStatus = false;
                }
            },

            async updateIntegrationRelease() {
                try {
                    this.loading.saving = true;

                    console.log(this.modelChangeStaus);
                    let res = await IntegrationService.UpdateIntegrationReleaseStatus(this.modelChangeStaus);

                    if (res.status == 200) {
                        this.$toast.success("Zaktualizowano status aplikacji");
                        this.$emit("current-release-item", res.data);
                        document.getElementById("close-changeReleaseStatus").click();
                    }
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading.saving = false;
                }
            },
            
            changeReleaseStatusClose() {
                this.modelChangeStaus.StatusID = "";
                this.modelChangeStaus.ReleaseID = -1;
            }
        }
    }
</script>
<style type="text/css">
</style>