<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-between px-md-1">
                <div class="align-self-center">
                    <i class="far fa-comment-alt text-warning fa-3x"></i>
                </div>
                <div class="text-end">
                    <h3>{{convertBytes(integrationSize)}}</h3>
                    <p class="mb-0">Zajmowana przestrzeń</p>
                </div>
            </div>
        </div>
        <BoxLoader v-if="loading.fetch" />
    </div>
</template>

<script charset="utf-8">
    import IntegrationService from "@/services/integration/integration.service";
    import HelperService from "@/services/common/helper.service.js"
    import BoxLoader from "@/components/common/BoxLoader.vue"
    export default {
        components: {
            BoxLoader
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,

                integrationSize: 0,

                loading: {
                    fetch: false
                },
            };
        },
        computed: {

        },
        mounted() {
            this.fetchIntegrationSize();
        },
        methods: {
            async fetchIntegrationSize() {
                try {
                    this.loading.fetch = true;

                    let res = await IntegrationService.GetReleasesSizes();

                    if (res.status == 200) {
                        this.integrationSize = res.data;
                    }
                }
                catch (e) {
                    console.log(e)
                }
                finally {
                    this.loading.fetch = false;
                }
            },

            convertBytes(bytes) {
                return HelperService.ConvertFileSize(bytes);
            }
        }
    }</script>
<style type="text/css">
</style>