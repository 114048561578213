import url from "@/services/common/url";
import api from "@/services/common/api";

class IntegrationService {
    GetNewestRelease() {
        return api.get(url.integration.getNewestRelease)
            .then((res) => { return res });
    }

    GetAllReleases() {
        return api.get(url.integration.getAllReleases)
            .then((res) => { return res });
    }

    GetAllReleasesWithChanges() {
        return api.get(url.integration.getAllReleasesWithChanges)
            .then((res) => { return res });
    }

    GetReleaseById(id) {
        return api.get(url.integration.getReleaseById + "/" + id)
            .then((res) => { return res });
    }

    GetReleaseByIdWithChanges(id) {
        return api.get(url.integration.getReleaseByIdWithChanges + "/" + id)
            .then((res) => { return res });
    }

    AddChange(req) {
        return api.post(url.integration.addChange, req)
            .then((res) => { return res });
    }

    EditChange(req) {
        return api.post(url.integration.editChange, req)
            .then((res) => { return res });
    }
    RemoveChange(id) {
        return api.delete(url.integration.removeChange + "/" + id)
            .then((res) => { return res });
    }

    AddRelease(req) {
        return api.post(url.integration.addRelease, req)
            .then((res) => { return res });
    }

    DeleteRelease(id) {
        return api.delete(url.integration.deleteRelease + "/" + id)
            .then((res) => { return res });
    }

    AddFile(req, onProgress) {
        let config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: progressEvent => {
                var percentCompleted = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                if (onProgress) onProgress(percentCompleted, progressEvent.loaded, progressEvent.total);

                return percentCompleted;
            }
        }

        return api.post(url.integration.addFile, req, config)
            .then((res) => { return res });
    }

    RemoveFile(id) {
        return api.delete(url.integration.removeFile + "/" + id)
            .then((res) => { return res });
    }

    UpdateFileName(id, req) {
        return api.put(url.integration.updateFileName + `/${id}`, req)
            .then((res) => { return res });
    }

    GetReleasesCount() {
        return api.get(url.integration.getReleasesCount).then((res) => { return res })
    
    }
    GetReleasesSizes() {
        return api.get(url.integration.getReleasesSizes).then((res) => { return res });
    }

    UpdateDownloadDescirption(req) {
        return api.put(url.integration.updateDownloadDescirption, req).then((res) => { return res });
    }

    GetAvailableReleaseStatuses() {
        return api.get(url.integration.getAvailableReleaseStatuses).then((res) => { return res });
    }

    GetAvailableReleasePublicationStatuses() {
        return api.get(url.integration.getAvailableReleasePublicationStatuses).then((res) => { return res });
    }

    UpdateIntegrationReleaseStatus(req) {
        return api.put(url.integration.updateIntegrationReleaseStatus, req).then((res) => { return res });
    }

    UpdateIntegrationReleasePublication(req) {
        return api.put(url.integration.updateIntegrationReleasePublication, req).then((res) => { return res });
    }
}

export default new IntegrationService();